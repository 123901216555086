import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/common.css'
import comJs from './assets/js/com.js'
Vue.use(comJs);

import NutUI from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';
NutUI.install(Vue);

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'


let options = {
  fullscreenEl: false,
  tapToClose: true,
}

Vue.use(preview, options)
Vue.use(preview)

// import VueAwesomeSwiper from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
// Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.prototype.$EventBus = new Vue()
import iView from 'iview'
import 'iview/dist/styles/iview.css'
Vue.use(iView)

import Router from 'vue-router'

 
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
 

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
