<template>
    <div id="mobile">
        <div class="title">
            <img src="@/assets/images/logo.png" alt="" @click="loc">
            <span></span>
            <button @click="unfold">
             <!-- <button > -->
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
        <div class="main">
            <router-view></router-view>
        </div>
        <div class="bottom">
                <a href="https://beian.miit.gov.cn/">
           
                    版权所有：深圳市君语科技有限公司 粤ICP备16065331号
                </a>
                <p>
                    Copyright © 2016-2020 junyu Technology  All Reserved.
                </p>
            </div>
        <div class="footer">
            <div class="phone">
                <img src="@/assets/images/phone.png" alt="">
                <span><a href="tel:0755-33165105">0755-33165105</a></span>
            </div>
              <div class="line">
                <span @click="kefu">在线咨询</span>
            </div>
              <div class="phone eml">
                <img src="@/assets/images/email.png" alt="">
                <span><a href="mailto:234513612@qq.com">234513612@qq.com</a></span>
            </div>
        </div>
        <div class="state" v-if="sel_state" @ @click="clear">
            <p v-for="item in list" :key="item.id" @click="SEL" @click.stop>
                <router-link :to="{name: item.path}">
                    {{item.name}}
                </router-link>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name:'mobile',
    data(){
        return{
            sel_state:false,
            list:[
                {
                    id:1,
                    name:'SaaS',
                    path: 'Invoke',
                },
                {
                    id:2,
                    name:'下载中心',
                    path:'download'
                },
                
                // {
                //     id:3,
                //     name:'云通信',
                //     // path:'robot'
                // },
                // {
                //     id:3,
                //     name:'君语小号',
                //     path:'junyu'
                // },
                // {
                //     id:4,
                //     name:'PaaS',
                //     path:'calling'
                // },
                {
                    id:5,
                    name:'关于我们',
                    path:'reference'
                },
               

            ]
            
        }
    },
    methods:{
        unfold(){
            this.sel_state = true
        },
        SEL(){
            this.sel_state= false
        },
        loc(){
            this.$router.push({
                name:'page'
            })
            
        },
        clear(){
            this.sel_state =false
        },
        kefu(){
            window.open("http://wpa.qq.com/msgrd?v=3&uin=234513612&site=www.cactussoft.cn&menu=yes")
    },
    },
}
</script>
<style lang="scss" scpoed>
  @import "./mobile.scss";
  
</style>