<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: "HanHei SC", "PingHei", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  width: 100%;
  height: 100%;
  font-size: 14px;

  // position: relative;
}
</style>
<script>
export default {
  name:'app',
  methods:{
      _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted(){
    if(this._isMobile()){
        this.$router.replace('/terminal');
        //移动端
    }else{
        // this.$router.replace('/NoData');
        this.$router.replace('/reactive')
        //pc端
    }
  }
}
</script>