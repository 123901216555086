//classList的垫片
import "classlist-polyfill";
//插件的包
import PerfectScrollbar from 'perfect-scrollbar';
//对应的css
import "perfect-scrollbar/css/perfect-scrollbar.css";

export default {
  install(Vue, options) {
    Vue.component('PerfectScrollbar', PerfectScrollbar)
    const el_scrollBar = (el) => {
      if (el._ps_ instanceof PerfectScrollbar) {
        el._ps_.update();
      } else {
        el._ps_ = new PerfectScrollbar(el, {
          suppressScrollX: true
        });
      }
    };
    // 滚动条 封装 使用方法  v-scrollBar
    Vue.directive("scrollBar", {
      inserted(el, binding, vnode) {
        const rules = ["fixed", "absolute", "relative"];
        if (!rules.includes(window.getComputedStyle(el, null).position)) {
          console.error(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join("、")}`)
        }
        el_scrollBar(el);
      },
      componentUpdated(el, binding, vnode, oldVnode) {
        try {
          vnode.context.$nextTick(
            () => {
              el_scrollBar(el);
            }
          )
        } catch (error) {
          console.error(error);
          el_scrollBar(el);
        }
      }
    })
  }
}
