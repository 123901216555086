<style lang="scss" scpoed>
#reactive {
  width: 100%;
  height: auto;
  background: #fff;
  .header {
    position: fixed;
    width: 100%;
    height: 72px;
    position: fixed;
    top: 0px;
    z-index: 99;
    color: #fff;
    overflow: hidden;
    .top {
      overflow: hidden;
      .left {
        float: left;
        line-height: 68px;
        img {
          height: 24px;
        }
      }
      .right {
        float: right;
        ul {
          display: flex;
          line-height: 72px;
          li {
            padding: 0px 15px;
            font-size: 14px;
          }
          li:hover {
            cursor: pointer;
            // color: #3875f6;
            background: #3875f6;
          }
        }
      }
    }
  }
  .banner {
    width: 100%;
    // height: 100%;
    position: relative;
    .ivu-carousel {
      margin: auto;
      .demo-carousel {
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    .common_title {
      .img_title {
        position: absolute;
        top: 40%;
        left: 19%;
        span {
          display: inline-block;
          font-size: 30px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 20px;
        }
        p {
          font-weight: 200;
          font-size: 20px;
          color: #fff;
          margin-bottom: 10px;
        }
        .shenqing {
          margin-top: 30px;
          cursor: pointer;
          width: 150px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          border-radius: 3px;
          font-size: 16px;
          display: inline-block;
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }
  .navigation {
    width: 100%;
    position: relative;
    margin-top: -100px;
    .navigation_main {
      // width: 100%;
      background: #f5f5f5;

      ul {
        overflow: hidden;
        display: flex;
        padding: 10px;
        li {
          text-align: center;
          padding: 10px;
          .img_bd {
            width: 70px;
            height: 70px;
            border: 1px solid #444444;
            border-radius: 50%;
            margin: 20px auto;
            position: relative;

            img {
              display: block;
              width: 35px;
              height: 35px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            &.last_img {
              img {
                width: 30px;
                height: 35px;
              }
            }
          }
          span,
          p {
            padding: 0px 15px;
            padding-bottom: 20px;
          }
          span {
            font-size: 18px;
            color: rgb(67, 67, 67);
            display: inline-block;
          }
        }
      }
    }
  }
  // 核心产品
  .services {
    padding-top: 60px;
    #com_title {
      margin-bottom: 60px;
    }
    .chanpin {
      background: #f5f5f5;
      ul {
        // margin-bottom: 100px;
        padding-bottom: 100px;
        &.add {
          li {
            .img {
              margin-right: 60px;
            }
          }
          #quzheng {
            p {
              line-height: 30px;
            }
            .img {
              width: 60%;
              img {
                width: 60%;
                display: block;
                margin-left: 50px;
              }
            }
          }
        }

        &.last_add {
          .text {
            margin: 0 auto;
            .r_t {
              width: 70%;
              display: block;
              margin: 0px auto;
            }
            .auto {
              width: 70%;
              display: block;
              margin: 0px auto;
              div {
                margin-top: 20px;
              }
            }
          }
          // .img{
          //     img{
          //         width: 80%;
          //         height: 80%;
          //     }
          // }
        }
        .list {
          .img {
            // margin-left: 60px;
          }
        }
        li {
          width: 100%;
          padding: 20px;
          display: flex;
          vertical-align: middle;
          &.first_top {
            .text {
              padding: 0px 30px 0px 0px;
            }
            .img {
              margin-top: -7%;
              img {
                width: 80%;
              }
            }
          }

          &.erweima_com {
            .text {
              padding-right: 30px;
              p {
                padding-bottom: 10px;
              }
            }
            .img {
              height: 80%;
              img {
                width: 80%;
              }
            }
            .erweima {
              text-align: center;
              margin: 0px 30px 0px 30px;
              // padding-top: 20px;
              img {
                height: 100px;
              }
            }
          }
          &.com_after {
            .text {
              .r_t {
                padding: 10px 0px;
              }
              .r_t:after {
                display: none;
              }
            }
          }
          .img {
            width: 50%;
            // margin-right: 60px;
            img {
              width: 100%;
              height: auto;
            }
          }
          .text {
            width: 50%;
            margin: 0px auto;
            .r_t {
              font-size: 24px;
            }
            .r_t::after {
              content: "";
              display: block;
              width: 50px;
              height: 10px;
              margin-top: 3px;
              margin-bottom: 14px;
              border-left-width: 1px;
              border-left-style: solid;
              border-bottom-width: 1px;
              border-bottom-style: solid;
              margin-left: 10px;
            }
            p {
              line-height: 24px;
              text-align: left;
            }
          }
          &.com_list {
            .text {
              margin: 0 auto;
              .r_t {
                width: 70%;
                margin: auto;
                display: 0px block;
              }
              .auto {
                width: 70%;
                display: block;
                margin: 0px auto;
              }
            }
          }
          .erweima {
            padding-top: 65px;
            img {
              width: 100px;
              height: 100px;
            }
            p {
              color: #3875f6;
            }
          }
        }
      }
    }
  }
  .e_commerce {
    padding-top: 60px;
    width: 100%;
    .detail {
      .detail_title {
        padding: 0 20px;
      }
      .detail_img {
        .client {
          margin: 20px 0;
          font-size: 26px;
        }
        margin: 0 0 0 10px;
        width: 100%;
        display: block;
        img {
          width: 20%;
          margin-left: 10px;
        }
      }
      .detail_admin {
        overflow: hidden;
        width: 90%;
        margin: 0 10px;
        .client {
          margin: 20px 0;
          font-size: 26px;
        }
        img {
          display: block;
          width: 90%;
          margin-left: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  // 行业应用
  .territory {
    padding-top: 60px;
    width: 100%;
    background-image: url("../assets/images/copy.jpg");
    position: relative;
    bottom: 0px;
    right: 0px;
    top: 0px;
    left: 0px;
    height: 1000px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
    .img_c {
      height: 100%;
      color: rgb(12, 52, 61);
      // background-color: rgb(12, 52, 61);
      // background-image: linear-gradient(currentcolor, transparent);
      opacity: 0.8;
      z-index: 0;
      bottom: 0px;
      right: 0px;
      top: 0px;
      left: 0px;
      #com_title {
        .hexin {
          color: #fff !important;
        }
      }
    }
    .img {
      margin: auto;
      img {
        display: block;
        margin: auto;
        height: 570px;
      }
    }
  }
  // 合作共赢
  .partner {
    width: 100%;
    padding-top: 60px;
    .partner_main {
      ul {
        overflow: hidden;

        padding: 10px;
        li {
          float: left;
          width: 25%;
          height: 300px;
          padding: 10px;
          text-align: center;
          .img {
            padding: 30px 30px 10px;
          }
          span,
          p {
            padding: 0px 15px;
            padding-bottom: 20px;
          }
          span {
            font-size: 18px;
            color: rgb(67, 67, 67);
            display: inline-block;
          }
          &:hover {
            // height: 290px;
            box-shadow: rgba(0, 0, 0, 0.188235) 0px 0px 10px 0px;
            // -webkit-transform: translate3d(0px,-2px,0px);
            // transform: translate3d(0px,-2px,0px);
            // transition: all 0.3s cubic-bezier(0.55,0,0.1,1);
            transition: All 0.3s ease;
            z-index: 10;
          }
        }
      }
    }
  }
  // 联系我们
  .contact {
    width: 100%;
    background: #f5f5f5;
    padding-top: 60px;
    padding-top: 60px;
    .dizhi {
      text-align: center;
      font-size: 20px;
      color: #1a1a1a;
      .china {
        span {
          display: inline-block;
          margin: 10px 0px;
          padding: 0px 25px 10px;
          border-bottom: 1px solid #999999;
        }
      }
      .img_text {
        // margin-bottom: 10px;
        cursor: pointer;
        padding-bottom: 10px;
        vertical-align: middle;
        img {
          height: 15px;
          padding-right: 10px;
        }
        span {
          vertical-align: middle;
          a {
            color: #000000;
            border-bottom: 1px solid #000000;
          }
        }
      }
    }
  }
  // 信息反馈
  .feedback {
    width: 100%;
    height: auto;
    background: #f5f5f5;
    padding-bottom: 50px;
    padding-top: 60px;
    .feedback_main {
      // margin: 0px 20px;
      width: 70%;
      margin: 0 auto;
      input {
        width: 48%;
        border: none;
        outline: none;
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
        border: solid 1px #999999;
        color: inherit;
        margin-left: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
      }
      textarea {
        border: none;
        outline: none;
        width: 98%;
        margin: 0px 10px;
        height: 60px;
        min-height: 120px;
        border: solid 1px #999999;
        border-radius: 0px;
        padding: 10px;
      }
      button {
        display: block;
        cursor: pointer;
        border: none;
        outline: none;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 120px;
        border-radius: 60px;
        margin-left: 10px;
        color: #fff;
        background-color: #f0ad4e;
        border-color: #eea236;
        margin: 10px auto 0px;
      }
    }
  }
  .footer {
    background: #333333;
    padding: 30px 0px;
    color: #ffff;
    text-align: center;
    p {
      display: inline-block;
    }
    a {
      color: #fff;
    }
  }
  // 公共头部
  #com_title {
    padding-top: 60px;
    background-color: rgba(0, 0, 0, 0);
    padding-bottom: 60px;
    .cst_title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0);
      color: rgb(217, 217, 217);
      min-height: 20px;
      margin-top: 0px;
      .cst_left {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        margin-right: 20px;
        margin-left: 10px;
        border-top-color: currentcolor !important;
        margin-top: 30px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
      }
      .cst_right {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        margin-left: 20px;
        margin-right: 10px;
        border-top-color: currentcolor !important;
        margin-top: 30px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
      }
      .hexin {
        min-height: 0px;
        text-align: center;
        color: black;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 60%;
        h3 {
          font-family: Source-Han-Sans-Medium;
          margin-top: 10px;
          line-height: 1em;
          font-size: 24px;
          min-width: 10px;
          min-height: 10px;
          margin-bottom: 10px;
        }
        h1 {
          font-weight: 700;
          // line-height: 1.3em;
          margin-top: 10px;
          padding-left: 2px;
          padding-right: 2px;
          min-width: 10px;
          min-height: 10px;
          font-size: 32px;
          transform: scale(0.9, 1.4);
          margin-bottom: 10px;
        }
        img {
          height: 20px;
        }
      }
    }
  }
  .fiexd {
    position: fixed;
    width: 100px;
    height: auto;
    background: #337eff;
    font-size: 16px;
    bottom: 20%;
    right: 0px;
    color: #fff;
    border-radius: 4px;
    .wx_miapp {
      text-align: center;
      font-size: 14px;
      img {
        width: 100%;
        height: 100px;
        margin: auto;
        display: block;
      }
      p {
        margin: 10px 0;
      }
    }
  }
}
</style>
<template>
  <div id="reactive">
    <div class="header" :class="navBarFixed == true ? 'navBarWrap' :''">
      <div class="container top">
        <div class="left">
          <img src="@/assets/images/LOGO@2x.png" alt />
        </div>
        <div class="right">
          <ul>
            <li v-for="item in nav_list" :key="item.id" @click="Aiming(item.id)">{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="banner" id="banner">
      <Carousel
        :autoplay="setting.autoplay"
        :autoplay-speed="setting.autoplaySpeed"
        :dots="setting.dots"
        :trigger="setting.trigger"
        :arrow="setting.arrow"
        @on-change="swiper"
      >
        <Carousel-item>
          <div class="demo-carousel">
            <img src="@/assets/images/01.jpg" alt />
          </div>
        </Carousel-item>
        <Carousel-item>
          <div class="demo-carousel">
            <img src="@/assets/images/02.jpg" alt />
          </div>
        </Carousel-item>
        <Carousel-item>
          <div class="demo-carousel">
            <img src="@/assets/images/03.jpg" alt />
          </div>
        </Carousel-item>
        <Carousel-item>
          <div class="demo-carousel">
            <img src="@/assets/images/07.jpg" alt />
          </div>
        </Carousel-item>
        <Carousel-item>
          <div class="demo-carousel">
            <img src="@/assets/images/08.jpg" alt />
          </div>
        </Carousel-item>
      </Carousel>
      <div class="common_title">
        <div class="img_title" id="img_title">
          <span>{{common_list.name}}</span>
          <p>{{common_list.text}}</p>
          <p>{{common_list.process}}</p>
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="container navigation_main">
        <ul>
          <li>
            <div class="img_bd">
              <img src="@/assets/images/icon1.png" alt />
            </div>
            <span>精准获客</span>
            <p>通过百度、淘宝、抖音和头条等渠道推广引流，结合软文、表单、直播、媒体品牌推广和种草等多种方式获取精准客源。</p>
          </li>
          <li>
            <div class="img_bd">
              <img src="@/assets/images/icon2.png" alt />
            </div>
            <span>平台融合</span>
            <p>将推广平台、 呼叫中心、 短信系统、 CRM系统、 OA应用、商城、仓管和物流查询等系统融合成一个统一高效的平台。</p>
          </li>
          <li>
            <div class="img_bd">
              <img src="@/assets/images/icon3.png" alt />
            </div>
            <span>一键呼叫</span>
            <p>从导入或者从推广平台引流线索数据，分派给业务员，坐席查看线索详情，一键拨号，自动呼出。</p>
          </li>
          <li>
            <div class="img_bd last_img">
              <img src="@/assets/images/icon4.png" alt />
            </div>
            <span>安全保护</span>
            <p>从线索→电话→订单→发货，全程隐藏客户号码（*号保护），保护客户隐私，防止客户流失。</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 核心产品 -->
    <div class="services" id="services">
      <div class="container" id="com_title">
        <div class="cst_title">
          <hr class="cst_left" />
          <div class="hexin">
            <h3>核心产品</h3>
            <h1>CORE PRODUCTS</h1>
            <img src="@/assets/images/xiasanjiao.png" alt />
          </div>
          <hr class="cst_right" />
        </div>
      </div>
      <div class="chanpin">
        <div class="container">
          <ul class="add">
            <li class="first_top">
              <div class="img">
                <img src="@/assets/images/kongzhi.png" alt />
              </div>
              <div class="text">
                <div class="r_t">🅐 闪兔云</div>
                <p>闪兔云是一款专业的移动CRM销售管理应用，根据线索自动拨号，快速实现电话销售成单，全程订单跟踪。产品集成CRM、呼叫中心和OA等系统，助力企业构建高效的一站式营销管理平台，重塑移动互联时代全新的客户关系、伙伴关系，实现效率提升和业绩增长。</p>
              </div>
              <div class="erweima">
                <img src="@/assets/images/rabbit.png" alt />
                <p>闪兔云app下载</p>
              </div>
            </li>
          </ul>
          <ul class="event">
            <li class="list com_list com_after">
              <div class="text">
                <div class="r_t">产品特点</div>
                <div class="auto">
                  <p>1.支持多平台引流，如鲁班、推呀和黑眼圈等平台</p>
                  <p>2.支持线索数据导入</p>
                  <p>3.业务员可获取线索，也可以申请“更多线索”</p>
                  <p>4.支持线索一键回收，重新分发</p>
                  <p>5.支持多家快递平台对接</p>
                  <p>6.支持数据加密（手机号码加密）</p>
                  <p>7.实时线索统计</p>
                  <p>8.实时订单统计</p>
                  <p>9.实时业绩排名统计</p>
                  <p>10.支持考勤打卡及统计</p>
                </div>
              </div>
              <div class="img">
                <img src="@/assets/images/phongComputer.png" alt />
              </div>
            </li>
          </ul>

          <ul class="add">
            <li class="com_after com_list">
              <div class="img">
                <img src="@/assets/images/kj.png" alt />
              </div>
              <div class="text">
                <div class="r_t">流程说明</div>
                <div class="auto">
                  <p>1.互联网推广（获客）</p>
                  <p>2.将推广获取的线索引流至闪兔云平台（CRM）</p>
                  <p>3.分配线索给业务员（APP客户端或WEB终端）</p>
                  <p>4.业务员根据线索联系客户（点击拨号）</p>
                  <p>5.选择产品，沟通下单（产品管理）</p>
                  <p>6.提交订单，主管审核（订单管理）</p>
                  <p>7.仓管发货，物流跟踪（快递100等）</p>
                  <p>8.客户收货，快递代收货款（订单结束）</p>
                </div>
              </div>
            </li>
          </ul>
          <ul class="event">
            <li class="list erweima_com">
              <div class="erweima">
                <img src="@/assets/images/jyphong.png" alt />
                <p>君语电话app下载</p>
              </div>
              <div class="text">
                <div class="r_t">🅑 君语电话</div>
                <p>君语电话是一款完全免费的SIP网络电话，由客户自由设置注册服务器、账号和密码等信息，支持代理域、语音编码及前缀等设置，本产品只为方便开发者和运维人员测试软交换平台或线路调试使用。</p>
                <p class="fier">1.支持多平台自由注册</p>
                <p>2.支持多语音编码，并支持编码优先级设置</p>
                <p>3.支持自动接听</p>
                <p>4.支持呼叫前缀设置</p>
                <p>5.支持SIP域设置</p>
                <p>6.支持UDP和TCP协议</p>
                <p>7.支持注册及呼叫日志查看，方便故障分析</p>
              </div>
              <div class="img">
                <img src="@/assets/images/junyu_phone.png" alt />
              </div>
            </li>
          </ul>
          <ul class="add">
            <li class="first_top quzheng" id="quzheng">
              <div class="img" style="height:80%;width:100%">
                <img src="@/assets/images/quzheng01.png" alt />
              </div>
              <div class="text">
                <div class="r_t">🅒 录音取证电话</div>
                <!-- <p>
                                   通话自动录音,支持移动联通电话三大运营商，和正常通话一样；静默自动云端超高清录音，录音文件加密云存储，金融级数据安全存；链路全程SHA256安全加密，真实清晰不可篡改，提供最具法律效力的录音证据。
                </p>-->
                <p>1.通话自动录音，支持移动联通电信三大运营商</p>
                <p>2.静默自动云端超高清录音</p>
                <p>3.录音文件加密云存储，金融级数据安全存储</p>
                <p>4.链路全程SHA256安全加密，真实清晰不可篡改</p>
                <p>5.提供最具法律效力的录音取证</p>
                <p>6.记录每通来电通话时间，方便追溯</p>
                <p>7.自动识别通讯录: 通过app自带的通话录音功能获取通话记录与录音文件，方便手机拨号、查看。</p>
              </div>
              <a
                href="http://www.jypaas.com/app/jyrecord.apk"
                target="_blank"
                class="erweima"
                style="line-height:20px;cursor:pointer"
              >
                <img src="@/assets/images/record.png" alt />
                <p style="line-height:20px;cursor:pointer">录音取证电话下载</p>
              </a>
            </li>
          </ul>
          <ul class="add last_add">
            <li>
              <div class="img">
                <img src="@/assets/images/ch_List.png" alt style="margin-top:40px;" />
              </div>
              <div class="text">
                <div class="r_t">产品列表</div>
                <div class="auto">
                  <div>
                    <span>SaaS：</span>
                    <p>闪兔云、拓客帮</p>
                  </div>
                  <div>
                    <span>PaaS：</span>
                    <p>
                      云坐席、云总机、小君智能机器人、云会议
                      点击拨号、语音通知、隐私电话、语音验证码
                    </p>
                  </div>
                  <div>
                    <span>云通信：</span>
                    <p>君语电话、SDK、400电话、录音取证电话</p>
                  </div>
                  <div>
                    <span>短信：</span>
                    <p>验证码短信、会员营销短信、通知短信、视频短信</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 电商平台 -->
    <div class="e_commerce" id="e_commerce">
      <div class="container" id="com_title">
        <div class="cst_title">
          <hr class="cst_left" />
          <div class="hexin">
            <h3>哔啵商城</h3>
            <h1>E-COMMERCE PLATFORM</h1>
            <img src="@/assets/images/xiasanjiao.png" alt />
          </div>
          <hr class="cst_right" />
        </div>
      </div>
      <div class="detail container">
        <div class="detail_title">
          <h3>哔啵商城是一款为商家提供高效，便捷，安全可靠的的服务平台，与线下门店融合发展，支持H5移动端，微信小程序，APP等多端，商家根据自己的产品定制首页风格，可创建拼团，秒杀，砍价等多种优惠活动。</h3>
        </div>
        <div class="detail_img container">
          <p class="client">客户端</p>
          <img src="@/assets/images/user.png" alt />
          <img src="@/assets/images/shopping_home.png" alt />
          <img src="@/assets/images/sharp.png" alt />
          <img src="@/assets/images/sharp_detail.png" alt />
        </div>
        <div class="detail_admin container">
          <p class="client">后台管理</p>
          <img src="@/assets/images/crm_detail.png" alt />
        </div>
        <div class="detail_admin container">
          <img src="@/assets/images/crm.png" alt />
        </div>
      </div>
    </div>
    <!-- 行业应用 -->
    <div class="territory" id="territory">
      <div class="img_c">
        <div class="container" id="com_title">
          <div class="cst_title">
            <hr class="cst_left" />
            <div class="hexin">
              <h3>行业应用</h3>
              <h1>INDUSTRY APPLICATIONS</h1>
              <img src="@/assets/images/xiasanjiao1.png" alt />
            </div>
            <hr class="cst_right" />
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/xingyeyingyong1.png" alt />
        </div>
      </div>
    </div>
    <!-- 合作共赢 -->
    <div class="partner" id="partner">
      <div class="container" id="com_title">
        <div class="cst_title">
          <hr class="cst_left" />
          <div class="hexin">
            <h3>合作共赢</h3>
            <h1>WIN-WIN COOPERATION</h1>
            <img src="@/assets/images/xiasanjiao.png" alt />
          </div>
          <hr class="cst_right" />
        </div>
      </div>
      <div class="partner_main container">
        <ul>
          <li>
            <div class="img">
              <img src="@/assets/images/hezuo1.png" alt />
            </div>
            <span>电销推广</span>
            <p>为各种电销业务提供坐席/业务员的操作平台(包含APP)和管理平台。</p>
          </li>
          <li>
            <div class="img">
              <img src="@/assets/images/hezuo2.png" alt />
            </div>
            <span>系统集成</span>
            <p>系统集成，为CRM、行业软件等营销软件厂商提供语音、短信能力服务</p>
          </li>
          <li>
            <div class="img">
              <img src="@/assets/images/hezuo3.png" alt />
            </div>
            <span>线路供应</span>
            <p>95、中继、大号、小号、IMS等各种语音线路资源，通道短信资源。</p>
          </li>
          <li>
            <div class="img">
              <img src="@/assets/images/hezuo4.png" alt />
            </div>
            <span>大数据</span>
            <p>大数据营销</p>
            <p>大数据营销</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contact" id="contact">
      <div class="container" id="com_title">
        <div class="cst_title">
          <hr class="cst_left" />
          <div class="hexin">
            <h3>联系我们</h3>
            <h1>CANTACT US</h1>
            <img src="@/assets/images/xiasanjiao.png" alt />
          </div>
          <hr class="cst_right" />
        </div>
      </div>
      <div class="dizhi container">
        <div class="china">
          <p>CHINA.SHENZHEN</p>
          <span>中国.深圳</span>
        </div>
        <div class="img_text">
          <img src="@/assets/images/dingwei.png" alt />
          <span>深圳市龙华区1970科技小镇3栋407</span>
        </div>
        <div class="img_text">
          <img src="@/assets/images/youjian.png" alt />
          <span>wangfan@jypaas.com</span>
        </div>
        <div class="img_text">
          <img src="@/assets/images/dianhua.png" alt />
          <span>
            <a href="tel:0755-33165105">0755-33165105</a>
          </span>
        </div>
        <div class="img_text">
          <img src="@/assets/images/shouji.png" alt />
          <span>
            <a href="tel:13632597632">136-3259-7632</a>
          </span>
        </div>
        <div class="img_text" @click="kefu">
          <img src="@/assets/images/qq.png" alt />
          <span>234513612</span>
        </div>
      </div>
    </div>
    <div class="fiexd" v-if="fiexd">
      <div class="wx_miapp">
        <img src="@/assets/images/rabbit.jpg" alt />
        <p>闪兔云小程序</p>
      </div>
    </div>
    <!-- 信息反馈 -->
    <!-- <div class="feedback" id="feedback">
            <div class="container " id="com_title">
                 <div class="cst_title">
                     <hr class="cst_left">
                    <div class="hexin">
                        <h3>信息反馈</h3>
                        <h1>LEAVE YOUR MESSAGE</h1>
                        <img src="@/assets/images/xiasanjiao.png" alt="">
                    </div>
                    <hr class="cst_right">
                 </div>
            </div>
            <div class="container">
                <div class="feedback_main ">
                    <input type="text" placeholder="请输入姓名" v-model="name">
                    <input type="text" placeholder="请输入公司名称" v-model="company_name">
                    <input type="text" placeholder="请输入手机号" v-model="phone">
                    <input type="text" placeholder="请填写公司规模" v-model="scale">
                    <textarea name="" id="" cols="30" rows="10" placeholder="请填写需求说明" v-model="remark"></textarea>
                    <button @click="submit">提交</button>
                </div>
            </div>
             
    </div>-->
    <!-- 尾部 -->
    <div class="footer">
      <div class="container">
        <p>版权所有：深圳市君语科技有限公司</p>
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021125478号</a>
        <div class="junyu">Copyright © 2016-2020 junyu Technology All Reserved.</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { company_apply } from "@/ajax/api";
export default {
  name: "reactive",
  data() {
    return {
      fiexd: false,
      navBarFixed: false,
      name: "",
      company_name: "",
      phone: "",
      scale: "",
      remark: "",
      combine: "",
      explain: "(来自官网)",
      setting: {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: "none",
        trigger: "hover",
        arrow: "never"
      },
      nav_list: [
        {
          id: "1",
          name: "首页"
        },
        {
          id: "2",
          name: "核心产品"
        },
        {
          id: "6",
          name: "电商平台"
        },
        {
          id: "3",
          name: "行业应用"
        },
        {
          id: "4",
          name: "合作共赢"
        },
        {
          id: "5",
          name: "联系我们"
        }
      ],
      common_list: "",
      list_1: [
        {
          id: 1,
          name: "云呼叫中心",
          text: "保护作息人员健朗，保障系统运转",
          process: ""
        }
      ],
      chanpin_list: [
        {
          id: 1,
          name: "闪兔云",
          text: "新一代营销平台",
          process:
            "整合资源、数据和终端的一体化运营，为企业提供快速有效的推广业务"
        }
      ],
      fangan_list: [
        {
          id: 1,
          name: "企业解决方案",
          text: "全功能呼叫中心助理电销团队，实时掌握客户信息",
          process: "处理线索订单，大幅度提高运作效率"
        }
      ],
      aml_listL: [
        {
          id: 1,
          name: "客户案例",
          text: "从业十余载，服务100000+坐席",
          process: ""
        }
      ],
      women_list: [
        {
          id: 1,
          name: "关于我们",
          text: "全方位,一站式解决您的通信问题",
          process: "",
          btn: ""
        }
      ]
    };
  },
  methods: {
    submit() {
      if (this.scale == "") {
        this.scale = "1";
      }
      this.combine = this.remark + this.explain;
      let row = {
        company: this.company_name,
        scale: this.scale,
        name: this.name,
        mobile: this.phone,
        remark: this.combine
      };
      company_apply(row)
        .then(res => {
          alert("提交成功");
        })
        .catch(err => {
          alert(err.msg);
        });
    },
    swiper(oldValue, value) {
      if (value == "0" || value == undefined) {
        this.common_list = this.list_1[0];
      } else if (value == "1") {
        this.common_list = this.chanpin_list[0];
      } else if (value == "2") {
        this.common_list = this.fangan_list[0];
      } else if (value == "3") {
        this.common_list = this.aml_listL[0];
      } else if (value == "4") {
        this.common_list = this.women_list[0];
      }
    },
    hidemenu() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 72) {
        this.fiexd = true;
        this.navBarFixed = true;
        $(".header").css("background", "#00142e");
      } else {
        this.navBarFixed = false;
        this.fiexd = false;
        $(".header").css("background", "");
      }
    },
    Aiming(e) {
      if (e == 2) {
        this.$el.querySelector("#services").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (e == 3) {
        this.$el.querySelector("#territory").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (e == 4) {
        this.$el.querySelector("#partner").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (e == 5) {
        this.$el.querySelector("#contact").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (e == 1) {
        this.$el.querySelector("#banner").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (e == 6) {
        this.$el.querySelector("#e_commerce").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      }
    },
    kefu() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=234513612&site=www.cactussoft.cn&menu=yes"
      );
    }
  },
  mounted() {
    this.swiper();
    window.addEventListener("scroll", this.hidemenu);
  }
};
</script>