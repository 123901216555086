import axios from 'axios'
import Qs from 'qs'
import store from '../store/index'
var baseUrl = 'https://seat.voxcoo.com'
export {
    baseUrl
}
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.baseURL = baseUrl;

// axios.defaults.withCredentials = true;
// axios.defaults.crossDomain = true;
// 请求拦截
axios.interceptors.request.use( config => { 
        // 在发送请求之前做些什么 验证token之类的
        return config; 
    }, error => { 
        // 对请求错误做些什么
        return Promise.error(error); 
})
axios.interceptors.response.use(response => {
    if (response.data.code == 1) {
        return Promise.resolve(response);
    }else {
        return Promise.reject(response.data);
    }       
},error => {

}
);
// 封装get请求
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return axios({
        method: 'get',
        url: url,
        headers: {
            "Content-Type": "application/json:charset=UTF-8"
        },
        params: params
    })
    .then(response => response.data)

    .catch(err => {
        return Promise.reject(err)
    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// 封装post请求
export function postFrom(url, data) {
    return axios({
        method: 'post',
        url: url,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data,
        transformRequest: [function (data) {
            let ret = '';
            for (var i in data) {
                ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
            }
            return ret
        }]
    })
    .then(response => response.data)
    .catch(err => {
        return Promise.reject(err)
    })
}
export function postJson(url, data) {
    return axios({
        method: 'post',
        url: url,
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
    .then(response => response.data)
    .catch(err => {
        return Promise.reject(err)
    })
}