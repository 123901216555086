<template>
  <div class="terminal">
    <div class="top" :class="navBarFixed == true ? 'navBarWrap' :''">
      <img src="@/assets/images/LOGO@2x.png" alt />
      <div @click="screen" class="sel">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="banner" id="banner">
      <nut-swiper
        direction="horizontal"
        :loop="true"
        :canDragging="false"
        :paginationVisible="true"
        ref="demo2"
        :autoPlay="3000"
      >
        <div class="nut-swiper-slide">
          <img src="@/assets/images/01.jpg" alt />
        </div>
        <div class="nut-swiper-slide gray_1">
          <img src="@/assets/images/02.jpg" alt />
        </div>
        <div class="nut-swiper-slide gray_1">
          <img src="@/assets/images/03.jpg" alt />
        </div>
        <div class="nut-swiper-slide gray_1">
          <img src="@/assets/images/07.jpg" alt />
        </div>
        <div class="nut-swiper-slide gray_1">
          <img src="@/assets/images/08.jpg" alt />
        </div>
      </nut-swiper>
    </div>
    <div id="product">
      <ul>
        <li>
          <div class="img">
            <img src="@/assets/images/icon1.png" alt />
          </div>
          <p>精准获客</p>
          <span>通过百度、淘宝、抖音和头条等渠道推广引流，结合软文、表单、直播、媒体品牌推广和种草等多种方式获取精准客源。</span>
        </li>
        <li>
          <div class="img">
            <img src="@/assets/images/icon2.png" alt />
          </div>
          <p>平台融合</p>
          <span>将推广平台、 呼叫中心、 短信系统、 CRM系统、 OA应用、商城、仓管和物流查询等系统融合成一个统一高效的平台。</span>
        </li>
      </ul>
      <ul>
        <li>
          <div class="img">
            <img src="@/assets/images/icon3.png" alt />
          </div>
          <p>一键呼叫</p>
          <span>从导入或者从推广平台引流线索数据，分派给业务员，坐席查看线索详情，一键拨号，自动呼出。</span>
        </li>
        <li>
          <div class="img last_img">
            <img src="@/assets/images/icon4.png" alt />
          </div>
          <p>安全保护</p>
          <span>从线索→电话→订单→发货，全程隐藏客户号码（*号保护），保护客户隐私，防止客户流失。</span>
        </li>
      </ul>
    </div>

    <!--核心产品 -->
    <div id="services" class="services">
      <div class="title">
        <p class="hexin">核心产品</p>
        <p class="eng">CORE</p>
        <p>
          <span></span>
          <span></span>
        </p>
        <p class="eng">PRODUCTS</p>
        <img src="@/assets/images/xiasanjiao.png" alt />
      </div>
      <div class="main">
        <div class="img">
          <img src="@/assets/images/picture1.png" alt=" " preview="1" />
        </div>
        <div class="zhanshi">
          <img src="@/assets/images/kongzhi.png" alt preview="1" />
        </div>
        <div class="a_sty">
          <div class="a_sty_title">
            <span>A</span>
            闪兔云
          </div>
          <p
            class="paragraph"
          >闪兔云是一款专业的移动CRM销售管理应用，根据线索自动拨号，快速实现电话销售成单，全程订单跟踪。产品集成CRM、呼叫中心和OA等系统，助力企业构建高效的一站式营销管理平台，重塑移动互联时代全新的客户关系、伙伴关系，实现效率提升和业绩增长。</p>
          <div class="flow">
            <p>业务流程图</p>
            <img src="@/assets/images/kj.png" alt preview="1" />
          </div>
          <div class="f_chart">
            <span>流程说明</span>
            <p class="fier">1.互联网推广（获客）</p>
            <p>2.将推广获取的线索引流至闪兔云平台（CRM）</p>
            <p>3.分配线索给业务员（APP客户端或WEB终端）</p>
            <p>4.业务员根据线索联系客户（点击拨号）</p>
            <p>5.选择产品，沟通下单（产品管理）</p>
            <p>6.提交订单，主管审核（订单管理）</p>
            <p>7.仓管发货，物流跟踪（快递100等）</p>
            <p>8.客户收货，快递代收货款（订单结束）</p>
          </div>
          <div class="f_chart">
            <span>产品特点</span>
            <p class="fier">1.支持多平台引流，如鲁班、推呀和黑眼圈等平台</p>
            <p>2.支持线索数据导入</p>
            <p>3.业务员可获取线索，也可以申请“更多线索”</p>
            <p>4.支持线索一键回收，重新分发</p>
            <p>5.支持多家快递平台对接</p>
            <p>6.支持数据加密（手机号码加密）</p>
            <p>7.实时线索统计</p>
            <p>8.实时订单统计</p>
            <p>9.实时业绩排名统计</p>
            <p>10.支持考勤打卡及统计</p>
          </div>
          <div class="f_chart">
            <img
              src="@/assets/images/rabbit.jpg"
              alt
              style="display:block;margin:10px auto 0;width:180px;"
            />
            <p
              style="text-align:center;color:#fff;background:#3875f6;width:180px;display:block;margin:0 auto 20px;border-radius:0 0 4px 4px;"
            >闪兔云小程序</p>
          </div>

          <button class="sty_btn" @click="s_app">闪兔云APP下载</button>

          <!-- 产品B -->
          <div class="j_phone">
            <img src="@/assets/images/junyu_phone.png" alt preview="2" />
          </div>
          <div class="common_change">
            <div class="a_sty_title">
              <span>B</span>
              君语电话
            </div>
            <p
              class="paragraph"
            >君语电话是一款完全免费的SIP网络电话，由客户自由设置注册服务器、账号和密码等信息，支持代理域、语音编码及前缀等设置，本产品只为方便开发者和运维人员测试软交换平台或线路调试使用。</p>
            <div class="f_chart">
              <span>产品特点</span>
              <p class="fier">1.支持多平台自由注册</p>
              <p>2.支持多语音编码，并支持编码优先级设置</p>
              <p>3.支持自动接听</p>
              <p>4.支持呼叫前缀设置</p>
              <p>5.支持SIP域设置</p>
              <p>6.支持UDP和TCP协议</p>
              <p>7.支持注册及呼叫日志查看，方便故障分析</p>
            </div>
            <button class="sty_btn" @click="jy_phone">君语电话APP下载</button>
          </div>

          <!-- 产品C -->
          <div>
            <div class="img">
              <img
                src="@/assets/images/quzheng01.png"
                alt=" "
                preview="1"
                style="width:100%;height:100%;display:block;margin:0 auto;"
              />
            </div>
          </div>
          <div class="a_sty_title">
            <span>c</span>
            录音取证电话
          </div>
          <p
            class="paragraph"
          >通话自动录音，支持移动联通电信三大运营商，和正常通话一样；静默自动云端超高清录音，录音文件加密云存储，金融级数据安全存；链路全程SHA256安全加密，真实清晰不可篡改，提供最具法律效力的录音证据</p>
          <div class="f_chart">
            <span>产品特点</span>
            <p class="fier">1.通话自动录音，支持移动联通三大运营商</p>
            <p>2.静默自动云端超高清录音</p>
            <p>3.录音文件加密云存储，金融级数据安全存储</p>
            <p>4.链路全程SHA256安全加密，真实清晰不可篡改</p>
            <p>5.提供最具法律效力的录音取证</p>
            <p>6.记录每通来电通话时间，方便追溯</p>
            <p>7.自动识别通讯录: 通过app自带的通话录音功能获取通话记录与录音文件，方便手机拨号、查看。</p>
          </div>
          <a href="http://www.jypaas.com/app/jyrecord.apk" target="_blank">
            <button class="sty_btn" @click="record_phone">录音取证APP下载</button>
          </a>
          <div class="j_phone">
            <img src="@/assets/images/ch_List.png" alt preview="3" style="margin-top:20px" />
          </div>

          <div class="product_list">
            <span class="lsit">产品列表</span>
          </div>
          <div class="product_list">
            <ul>
              <li>
                <span>SaaS：</span>
                <p>闪兔云、拓客帮</p>
              </li>
              <li>
                <span>PaaS：</span>
                <p>
                  云坐席、云总机、小君智能机器人、云会议
                  点击拨号、语音通知、隐私电话、语音验证码
                </p>
              </li>
              <li>
                <span>云通信：</span>
                <p>君语电话、SDK、400电话、录音取证电话</p>
              </li>
              <li>
                <span>短信：</span>
                <p>验证码短信、会员营销短信、通知短信、视频短信</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 电商平台 -->
    <div class="e_commerce" id="e_commerce">
      <div class="title partner_title">
        <p class="hexin">哔啵商城</p>
        <p class="eng">E-COMMERCE</p>
        <p>
          <span></span>
          <span></span>
        </p>
        <p class="eng">PLATFORM</p>
        <img src="@/assets/images/xiasanjiao.png" alt />
      </div>
      <div class="detail">
        <div class="detail_title">
          <h3>哔啵商城是一款为商家提供高效，便捷，安全可靠的的服务平台，与线下门店融合发展，支持H5移动端，微信小程序，APP等多端，商家根据自己的产品定制首页风格，可创建拼团，秒杀，砍价等多种优惠活动。</h3>
        </div>
        <div class="detail_img">
          <p class="client">客户端</p>
          <nut-swiper
            direction="horizontal"
            :loop="true"
            :canDragging="false"
            :paginationVisible="true"
            :pagination-color="color"
            :autoPlay="0"
          >
            <div class="nut-swiper-slide">
              <img src="@/assets/images/user.png" alt preview="9" />
            </div>
            <div class="nut-swiper-slide gray_1">
              <img src="@/assets/images/shopping_home.png" preview="9" alt />
            </div>
            <div class="nut-swiper-slide gray_1">
              <img src="@/assets/images/sharp.png" alt preview="9" />
            </div>
            <div class="nut-swiper-slide gray_1">
              <img src="@/assets/images/sharp_detail.png" preview="9" alt />
            </div>
          </nut-swiper>
        </div>
        <div class="detail_img">
          <p class="client">后台管理</p>
          <img src="@/assets/images/mobile_crm.png" alt style="padding: 0 20px" preview="10" />
        </div>
      </div>
    </div>
    <!-- 行业应用 -->
    <div id="territory">
      <!-- <div class="coniner">
                <div class="title common_title">
                    <p class="hexin">行业应用</p>
                    <p>INDYSTRY</p>
                    <p>
                        <span></span>
                        <span></span>
                    </p>
                    <p class="eng">APPLICATIONS</p>
                    <img src="@/assets/images/black.png" alt=""> 
                </div>
            </div>
            <div class="lose_top">
                <img src="@/assets/images/room_gg.png" alt="">
      </div>-->
      <img src="@/assets/images/xingyeyingyong.png" alt />
    </div>

    <!-- 合作共赢  -->
    <div id="partner">
      <div class="title partner_title">
        <p class="hexin">合作共赢</p>
        <p class="eng">WIN-WIN</p>
        <p>
          <span></span>
          <span></span>
        </p>
        <p class="eng">COOPERATION</p>
        <img src="@/assets/images/xiasanjiao.png" alt />
      </div>
      <ul>
        <li>
          <div class="img">
            <img src="@/assets/images/hezuo1.png" alt />
          </div>
          <p>电销推广</p>
          <span>为各种电销业务提供坐席/业务员的操作平台(包含APP)和管理平台。</span>
        </li>
        <li>
          <div class="img">
            <img src="@/assets/images/hezuo2.png" alt />
          </div>
          <p>系统集成</p>
          <span>系统集成，为CRM、行业软件等营销软件厂商提供语音、短信能力服务</span>
        </li>
        <li>
          <div class="img">
            <img src="@/assets/images/hezuo3.png" alt />
          </div>
          <p>线路供应</p>
          <span>95、中继、大号、小号、IMS等各种语音线路资源，通道短信资源。</span>
        </li>
        <li>
          <div class="img">
            <img src="@/assets/images/hezuo4.png" alt />
          </div>
          <p>大数据</p>
          <span>大数据营销</span>
          <span>失联修复</span>
        </li>
      </ul>
    </div>
    <!-- 联系我们 -->
    <div id="contact">
      <div class="title">
        <p class="hexin">联系我们</p>
        <p class="eng">CANTACT</p>
        <p>
          <span></span>
          <span></span>
        </p>
        <p class="eng">US</p>
        <img src="@/assets/images/xiasanjiao.png" alt />
      </div>
      <div class="china">
        <p>CHINA.SHENZHEN</p>
        <span>中国.深圳</span>
      </div>
      <div class="img_text">
        <img src="@/assets/images/dingwei.png" alt />
        <span>深圳市龙华区1970科技小镇3栋407</span>
      </div>
      <div class="img_text">
        <img src="@/assets/images/youjian.png" alt />
        <span>wangfan@jypaas.com</span>
      </div>
      <div class="img_text">
        <img src="@/assets/images/dianhua.png" alt />
        <span>
          <a href="tel:0755-33165105">0755-33165105</a>
        </span>
      </div>
      <div class="img_text">
        <img src="@/assets/images/shouji.png" alt />
        <span>
          <a href="tel:13632597632">136-3259-7632</a>
        </span>
      </div>
      <div class="img_text" @click="kefu">
        <img src="@/assets/images/qq.png" alt />
        <span>234513612</span>
      </div>
    </div>
    <!-- 信息反馈 -->
    <!-- <div id="feedback" >
            <div class="title">
                 <p class="hexin">信息反馈</p>
                  <p class="eng">LEAVE YOUR</p>
                 <p>
                     <span></span>
                     <span></span>
                 </p>
                 <p class="eng">MESSAGE</p>
                 <img src="@/assets/images/xiasanjiao.png" alt="">
             </div>
             <div class="feedback_main">
                 <input type="text" placeholder="请输入姓名" v-model="name">
                 <input type="text" placeholder="请输入公司名称" v-model="company_name">
                 <input type="text" placeholder="请输入手机号" v-model="phone">
                 <input type="text" placeholder="请填写公司规模" v-model="scale">
                 <textarea name="" id="" cols="30" rows="10" placeholder="请填写需求说明" v-model="remark"></textarea>
                 <button @click="submit">提交</button>
             </div>
    </div>-->
    <!--尾部  -->
    <div class="footer">
      <p>版权所有：深圳市君语科技有限公司</p>
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021125478号</a>
      <p>Copyright © 2016-2020 junyu Technology All Reserved.</p>
    </div>
    <div class="state">
      <ul>
        <li
          v-for="item in nav_list"
          :key="item.id"
          @click="loc_s(item.id)"
          :class="{'active':item.id == select}"
        >{{item.name}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { company_apply } from "@/ajax/api";
import $ from "jquery";
export default {
  name: "terminal",
  data() {
    return {
      color: "#fa3d15",
      name: "",
      company_name: "",
      phone: "",
      scale: "",
      remark: "",
      combine: "",
      explain: "(来自官网)",
      navBarFixed: false,
      state: false,
      select: 0,
      setting: {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: "inside",
        trigger: "hover",
        arrow: "never"
      },
      msg: "提交成功",
      nav_list: [
        {
          id: "1",
          name: "首页"
        },
        {
          id: "2",
          name: "核心产品"
        },
        {
          id: "6",
          name: "电商平台"
        },
        {
          id: "3",
          name: "行业应用"
        },
        {
          id: "4",
          name: "合作共赢"
        },
        {
          id: "5",
          name: "联系我们"
        }
      ]
    };
  },
  methods: {
    onChange() {},
    screen() {
      $(".state").slideToggle();
    },
    kefu() {
      window.open(
        "https://wpa.qq.com/msgrd?v=3&uin=234513612&site=www.cactussoft.cn&menu=yes"
      );
    },
    loc_s(e) {
      this.select = e;
      $(".state").slideToggle();
      if (this.select == 1) {
        this.$el.querySelector("#banner").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (this.select == 2) {
        this.$el.querySelector("#services").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (this.select == 3) {
        this.$el.querySelector("#territory").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (this.select == 4) {
        this.$el.querySelector("#partner").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (this.select == 5) {
        this.$el.querySelector("#contact").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      } else if (this.select == 6) {
        this.$el.querySelector("#e_commerce").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        });
      }
    },
    hidemenu() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //当滚动超过72时，实现吸顶效果（导航高度为72）
      if (scrollTop > 72) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    // 闪兔云APP下载
    s_app() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        window.location =
          "https://apps.apple.com/cn/app/%E9%97%AA%E5%85%94%E4%BA%91/id1511900354";
      } else {
        window.location = "https://www.jypaas.com/html/jump/rabbit.html";
      }
    },
    // 君语电话
    jy_phone() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        window.location =
          "https://apps.apple.com/cn/app/%E5%90%9B%E8%AF%AD%E7%94%B5%E8%AF%9D/id1523585790";
      } else {
        window.location = "http://www.jypaas.com/html/jump/jyphone.html";
      }
    },
    // 录音取证电话
    record_phone() {
      // window.location.href = 'http://www.jypaas.com/app/jyrecord.apk'
    },
    submit() {
      if (this.scale == "") {
        this.scale = "1";
      }
      this.combine = this.remark + this.explain;
      let row = {
        company: this.company_name,
        scale: this.scale,
        name: this.name,
        mobile: this.phone,
        remark: this.combine
      };
      company_apply(row)
        .then(res => {
          this.$toast.success("提交成功");
          this.clear();
        })
        .catch(err => {
          this.$toast.fail(err.msg);
        });
    },
    clear() {
      this.company_name = "";
      this.scale = "";
      this.name = "";
      this.phone = "";
      this.remark = "";
    }
  },
  mounted() {
    window.addEventListener("scroll", this.hidemenu);
  }
};
</script>
<style lang="scss" scpoed>
.terminal {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  .top {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 20px;
    position: fixed;
    top: 0;
    z-index: 999;
    img {
      margin-top: 15px;
      width: 105px;
      height: 19px;
    }
    .sel {
      border-color: #333;
      position: relative;
      border: 1px solid transparent;
      border-radius: 4px;
      top: 15px;
      span {
        background: #ffffff;
        border-radius: 1px;
        margin-top: 3px;
        display: block;
        width: 24px;
        height: 2px;
      }
    }
  }
  .navBarWrap {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    background: #0c3640;
  }
  .banner {
    width: 100%;
    height: 200px;
    position: relative;
    .nut-swiper-slide {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nut-swiper-pagination {
      .swiper-pagination-bullet {
        background: #fff;
        opacity: 1;
        &.active {
          background: #3875f6 !important;
        }
      }
    }
  }
  #product {
    margin-top: 20px;
    ul {
      display: flex;
      padding: 0px 10px;
      li {
        flex: 1;
        background: #f5f5f5;
        margin-bottom: 20px;
        .img {
          width: 60px;
          height: 60px;
          border: 1px solid #ccc;
          border-radius: 50%;
          background: #cccccc;
          margin: auto;
          img {
            display: block;
            margin: 10px auto 0px;
            width: 35px;
            height: 35px;
          }
        }
        p {
          font-size: 15px;
          color: #333333;
          margin: 20px 0px 15px 0px;
          text-align: center;
        }
        span {
          font-size: 13px;
          text-align: left;
          color: #666666;
        }
        .last_img {
          img {
            width: 26px;
            height: 35px;
          }
        }
      }
      li + li {
        margin-left: 20px;
      }
    }
  }
  // 核心产品  公共头部
  .services {
    width: 100%;
    height: auto;
    padding-top: 45px;
    .title {
      background: #ffffff;
      padding: 20px 20px;
      color: #000000;
      .hexin {
        margin-bottom: 19px;
        font-size: 20px;
      }
      p {
        width: 100%;
        padding: 0px 20px;
        line-height: 1;
        span {
          display: inline-block;
          width: 28%;
          height: 2px;
          margin-top: -5px;
          background: #999;
        }
        span:first-child {
          float: left;
        }
        span:last-child {
          float: right;
        }
        &.eng {
          font-weight: 700;
        }
      }
      img {
        display: block;
        margin: 20px auto 0;
      }
    }
    .main {
      width: 100%;
      height: auto;
      .img {
        padding: 20px;
        width: 100%;
        img {
          width: 100%;
          display: block;
          // margin:auto;
        }
      }
      .zhanshi {
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
        img {
          display: block;
          width: 90%;
          margin: auto;
        }
      }
      .a_sty {
        .record {
          background: #f5f5f5;
        }
        .a_sty_title {
          color: #333333;
          padding: 0px 20px;
          font-size: 20px;
          span {
            display: inline-block;
            width: 30px;
            height: 30px;
            background: #3875f6;
            color: #fff;
            border-radius: 50%;
            font-size: 22px;
            text-align: center;
            line-height: 30px;
            margin-right: 5px;
          }
        }
        .common_change {
          background: #fff;
          padding-bottom: 30px;
          padding-top: 20px;
        }

        .paragraph {
          padding: 20px 20px;
          color: #666666 !important;
          text-align: left;
        }
        .flow {
          // background: #fff;
          padding: 0px 10px 0px 20px;
          p {
            text-align: center;
            font-size: 20px;
            color: #1a1a1a;
            padding-top: 10px;
          }
          img {
            width: 95%;
            height: auto;
          }
        }
        .f_chart {
          border: 1px solid #999;
          margin: 20px;
          position: relative;
          padding: 0px 10px;
          margin-top: 40px;
          span {
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 15px;
            color: #1a1a1a;
            background: #cccccc;
            padding: 5px 10px;
            border-radius: 20px;
          }
          p {
            margin-top: 5px;
            font-size: 14px;
            &.fier {
              margin-top: 40px;
            }
          }
          p:last-child {
            margin-bottom: 20px;
          }
        }
        .sty_btn {
          display: block;
          padding: 10px 10px;
          color: #fff;
          background: #03cd9a;
          border: none;
          outline: none;
          border-radius: 36px;
          margin: auto;
          font-size: 14px;
          margin-bottom: 50px;
        }
        .j_phone {
          width: 100%;
          background: #fff;
          padding-bottom: 30px;
          img {
            padding: 0px 20px 0px 0px;
            width: 90%;
            margin-left: 30px;
            // height: 260px;
            border-radius: 5px;
          }
        }
        .chanpin_list {
          width: 100%;
          padding: 20px;
          img {
            width: 100%;
          }
        }
        .com_list {
          padding: 20px;
        }
        .product_list {
          padding: 20px 20px;
          background: #ffffff;
          span {
            font-size: 20px;
            color: #1a1a1a;
          }
          p {
            margin-bottom: 10px;
            color: #515a6e;
          }
        }
      }
    }
  }
  // 电商平台
  .e_commerce {
    //   background: #fff;
    padding-top: 70px;
    .partner_title {
      // background: #f5f5f5;
      color: #000000;
      .hexin {
        margin-bottom: 19px;
        font-size: 20px;
      }
      p {
        width: 100%;
        padding: 0px 20px;
        line-height: 1;

        span {
          display: inline-block;
          width: 24%;
          height: 2px;
          margin-top: -5px;
          background: #999;
        }
        span:first-child {
          float: left;
        }
        span:last-child {
          float: right;
        }
        &.eng {
          font-weight: 700;
        }
      }
      img {
        display: block;
        margin: 20px auto;
      }
    }
    .detail {
      width: 100%;
      .detail_title {
        padding: 20px;
        h3 {
          text-align: left;
          color: #515a6e;
          font-size: 14px;
        }
      }
      .detail_img {
        width: 100%;
        margin-top: 10px 20px;
        .nut-swiper {
          width: 100%;
          margin: 0 auto;
          height: 100% !important;
          img {
            width: 90%;
            margin-left: 5%;
            height: 600px;
          }
        }
        img {
          width: 100%;
        }
        .client {
          font-size: 16px;
          color: #000;
          font-weight: 700;
          padding: 20px;
        }
        .nut-swiper-pagination {
          .swiper-pagination-bullet {
            background: #000000;
            &.active {
              background: #ff3300 !important;
            }
          }
        }
      }
    }
  }
  // 行业应用
  #territory {
    height: auto;
    padding-top: 45px;
    img {
      width: 100%;
    }
  }
  //  合作共赢
  #partner {
    padding-top: 55px;
    background: #fff;
    .partner_title {
      // background: #f5f5f5;
      color: #000000;
      .hexin {
        margin-bottom: 19px;
        font-size: 20px;
      }
      p {
        width: 100%;
        padding: 0px 20px;
        line-height: 1;

        span {
          display: inline-block;
          width: 28%;
          height: 2px;
          margin-top: -5px;
          background: #999;
        }
        span:first-child {
          float: left;
        }
        span:last-child {
          float: right;
        }
        &.eng {
          font-weight: 700;
        }
      }
      img {
        display: block;
        margin: 20px auto;
      }
    }
    ul {
      overflow: hidden;
      li {
        width: 42%;
        float: left;
        margin-left: 20px;
        margin-bottom: 20px;
        .img {
          width: 60px;
          height: 60px;
          border: 1px solid #ccc;
          border-radius: 50%;
          background: #cccccc;
          margin: auto;
          img {
            display: block;
            margin: 10px auto 0px;
            width: 35px;
            height: 35px;
          }
        }
        p {
          font-size: 15px;
          color: #333333;
          margin: 20px 0px 15px 0px;
          text-align: center;
        }
        span {
          font-size: 13px;
          color: #666666;
          text-align: left;
        }
      }
      li:last-child {
        span {
          display: block;
          text-align: center;
        }
      }
    }
  }
  // 信息反馈
  #feedback {
    background: #ffffff;
    padding-bottom: 30px;
    padding-top: 45px;
    .title {
      // background: #f5f5f5;
      color: #000000;
      .hexin {
        margin-bottom: 19px;
        font-size: 20px;
      }
      p {
        width: 100%;
        padding: 0px 20px;
        line-height: 1;

        span {
          display: inline-block;
          width: 24%;
          height: 2px;
          margin-top: -5px;
          background: #999;
        }
        span:first-child {
          float: left;
        }
        span:last-child {
          float: right;
        }
        &.eng {
          font-weight: 700;
        }
      }
      img {
        display: block;
        margin: 20px auto;
      }
    }
    .feedback_main {
      // margin: 0px 20px;
      input {
        width: 45%;
        border: none;
        outline: none;
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
        border: solid 1px #999999;
        color: inherit;
        margin-left: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
      }
      textarea {
        border: none;
        outline: none;
        width: 92%;
        margin: 0px 10px;
        height: 60px;
        min-height: 120px;
        border: solid 1px #999999;
        border-radius: 0px;
        padding: 10px;
      }
      button {
        display: block;
        border: none;
        outline: none;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 120px;
        border-radius: 60px;
        margin-left: 10px;
        color: #fff;
        background-color: #f0ad4e;
        border-color: #eea236;
        margin: 10px auto 50px;
      }
    }
  }
  #contact {
    // background: #ffff;
    color: #000000;
    text-align: center;
    padding: 20px 0px;
    font-size: 14px;
    padding-top: 55px;
    .title {
      background: #f5f5f5;
      .hexin {
        margin-bottom: 19px;
        font-size: 20px;
      }
      p {
        width: 100%;
        padding: 0px 20px;
        line-height: 1;

        span {
          display: inline-block;
          width: 28%;
          height: 2px;
          margin-top: -5px;
          background: #999;
        }
        span:first-child {
          float: left;
        }
        span:last-child {
          float: right;
        }
        &.eng {
          font-weight: 700;
        }
      }
      img {
        display: block;
        margin: 20px auto;
      }
    }
    .china {
      span {
        display: inline-block;
        margin: 10px 0px;
        padding: 0px 25px 10px;
        border-bottom: 1px solid #999999;
      }
    }
    .img_text {
      margin-bottom: 10px;
      vertical-align: middle;
      img {
        height: 15px;
        padding-right: 10px;
      }
      span {
        vertical-align: middle;
        a {
          color: #000000;
          border-bottom: 1px solid #000000;
        }
      }
    }
  }
  .footer {
    background: #333333;
    text-align: center;
    color: #ffffff;
    padding: 20px 0px;
    a {
      color: #ffffff;
    }
  }
  .state {
    width: 30%;
    position: fixed;
    background: #0c3640;
    height: auto;
    display: none;
    z-index: 99;
    top: 50px;
    right: 0px;
    ul {
      overflow: hidden;
      li {
        color: #ffffff;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
      li:last-child {
        margin-bottom: 20px;
      }
      .active {
        width: 100%;
        background: #3875f6;
      }
    }
  }
}
</style>