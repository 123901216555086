import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import mobile from '../views/mobile.vue'
import terminal from '../views/terminal.vue'
import reactive from '../views/reactive.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/terminal', // 手机端首页
    name: terminal,
    component: terminal,
    meta:{
      title:'首页'
    },

  },
  {
    path: '/reactive', // pc端首页
    name: reactive,
    component: reactive,
    meta:{
      title:'首页'
    },
  }
  // {
  //   path: '/mobile', // 手机端首页
  //   name: mobile,
  //   component: mobile,
  //   redirect:{
  //     name:'page'
  //   },
  //   meta:{
  //     title:'首页'
  //   },
  //   children:[
  //      {
  //        name:'page',
  //        path:'page',
  //        component: ()=>import  ('@/sublevel/page'),
  //        meta: {
  //          title: '首页'
  //        },
  //      },
  //      {
  //       name:'calling',
  //       path:'calling',
  //       component: ()=>import  ('@/sublevel/calling'),
  //       meta: {
  //         title: '一键呼叫'
  //       },
  //     },
  //     {
  //       name:'Invoke',
  //       path:'Invoke',
  //       component: ()=>import  ('@/sublevel/Invoke'),
  //       meta: {
  //         title: '闪兔云'
  //       },
  //     },
  //     {
  //       name:'junyu',
  //       path:'junyu',
  //       component: ()=>import  ('@/sublevel/junyu'),
  //       meta: {
  //         title: '君语小号'
  //       },
  //     },
  //     {
  //       name:'reference',
  //       path:'reference',
  //       component: ()=>import  ('@/sublevel/reference'),
  //       meta: {
  //         title: '关于我们'
  //       },
  //     },
  //     {
  //       name:'robot',
  //       path:'robot',
  //       component: ()=>import  ('@/sublevel/robot'),
  //       meta: {
  //         title: '智能机器人'
  //       },
  //     },
  //     {
  //       name:'download',
  //       path:'download',
  //       component: ()=>import  ('@/sublevel/download'),
  //       meta: {
  //         title: 'app下载'
  //       },
  //     },

  //   ]
  // },
  // pc端
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home,
  //   redirect: {
  //     name: 'NoData'
  //   },
  //   meta: {
  //     title: '首页'
  //   },
  //   children:[
  //     {
  //       name:'NoData',
  //       path:'NoData',
  //       component: ()=>import  ('@/components/NoData'),
  //       meta: {
  //         title: '首页'
  //       },
  //     },
  //     {
  //       name:'ShowMore',
  //       path:'ShowMore',
  //       component: ()=>import  ('@/components/ShowMore'),
  //       meta: {
  //         title: '产品'
  //       },
  //     },
  //     {
  //       name:'solution',
  //       path:'solution',
  //       component: ()=>import  ('@/components/solution'),
  //       meta: {
  //         title: '解决方案'
  //       },
  //     },
  //     {
  //       name:'clients',
  //       path:'clients',
  //       component: ()=>import  ('@/components/clients'),
  //       meta: {
  //         title: '案例'
  //       },
  //     },
  //     {
  //       name:'support',
  //       path:'support',
  //       component: ()=>import  ('@/components/support'),
  //       meta: {
  //         title: '关于我们'
  //       },
  //     },
  //     {
  //       name:'Microsoft',
  //       path:'Microsoft',
  //       component: ()=>import  ('@/components/Microsoft'),
  //       meta: {
  //         title: '下载中心'
  //       },
  //     },
  //   ],

  // },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
