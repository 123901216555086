<template>
  <div id="Home">
    <div class="header" id="header">
      <div class="nav_top">
          <div class="container" :class="navBarFixed == true ? 'navBarWrap' :''">
                <div class="logo">
                    <a href="#" >
                      <img src="@/assets/images/logo.png" alt="">
                    </a>
                </div>
                <div class="navigation">
                    <ul class="navLists">
                      <li class="list"  id="shouye"> 
                          <!-- <router-link to="/NoData">首页</router-link> -->
                          <router-link to="/">首页</router-link>
                      </li>
                      <li class="list" id="chanpin" @mouseenter="chanpin"  @mouseleave="chanpin_leave">
                          <!-- <router-link to="/ShowMore">产品</router-link> -->
                          <router-link to="#">产品</router-link>
                          <!-- 产品 -->
                          <div class="hid" id="wu" >
                              <div class="product"> 
                                  <div class="container down">
                                      <!-- <p class="product_tilte">产品</p> -->
                                        <Row>
                                          <i-col span="24">
                                              <ul class="wilist">
                                                <li v-for="item in chanpinlist" :key="item.id" @click="SEL_CHANPIN(item.id)">
                                                  <span>{{item.name}}</span>
                                                  <p v-for="data in item.text" :key="data.id">
                                                     <router-link :to="{name:data.path}">
                                                       {{data.text}}
                                                        <span>{{data.name}}</span>
                                                     </router-link>
                                                  </p>
                                                </li>
                                              </ul>
                                          </i-col>
                                      </Row>
                                  </div>
                              </div>
                          </div>
                      </li>
                      <li class="list" id="fangan" @mouseleave="fangan_leave"  @mouseenter="fangan">
                          <!-- <router-link to="/solution">解决方案</router-link> -->
                          <router-link to="#">解决方案</router-link>
                         <div class="jiejue " id="jiejue" >
                            <div class="product"> 
                                <div class="container down">
                                    <!-- <p class="product_tilte">解决方案</p> -->
                                      <Row>
                                        <i-col span="24">
                                            <ul class="wilist">
                                              <li v-for="item in fanganlist" :key="item.id" @click="SEL_FANGAN(item.id)">
                                                <!-- <router-link :to="{name:item.path}">{{item.name}}</router-link> -->
                                                <span>{{item.name}}</span>
                                                <p>{{item.text}}</p>
                                              </li>
                                            
                                            </ul>
                                          
                                        </i-col>
                                    </Row>
                                </div>
                            </div>
                      </div>
                      </li>
                      <li class="list" id="anl">
                          <!-- <router-link to="/clients">案例</router-link> -->
                          <!-- <a href="">开发文档</a> -->
                          <a href="https://open.voxcoo.com/doc.html" target="_blank" rel="noopener noreferrer">开发文档</a>
                      </li>
                      <li class="list" id="women">
                          <router-link to="/support">关于我们</router-link>
                          <!-- <router-link to="/#">关于我们</router-link> -->
                      </li>
                      <!-- <li class="list" id="women">
                          <router-link to="/Microsoft">下载中心</router-link>
                      </li> -->
                    </ul>
                </div>
                <div class="right">
                  <div class="loginBtn">
                      <img src="@/assets/images/__phone.png" alt="">
                      <font size="3px">0755-33165105</font>
                      <!-- <button>登录</button>
                      <button>立即注册</button> -->
                  </div>
                </div>
          </div>
          
         <!-- <div class="jiejue " id="jiejue" @mouseleave="fangan_leave">
               <div class="product"> 
                  <div class="container down">
                      <p class="product_tilte">解决方案</p>
                        <Row>
                          <i-col span="24">
                              <ul>
                                <li>
                                  <router-link to="/ShowMore">电商解决方案</router-link>
                                  <p>出行解决方案</p>
                                </li>
                                <li>
                                  <router-link to="/ShowMore">出行解决方案</router-link>
                                  <p>云呼叫系统，安全稳定更贴心</p>
                                </li>
                                <li>
                                  <router-link to="/ShowMore">车联网解决方案</router-link>
                                  <p>更懂你的君语机器人</p>
                                </li>
                              </ul>
                            
                          </i-col>
                      </Row>
                  </div>
              </div>
         </div> -->
      </div>
    </div>
    <div class="main">
       <!-- 抽离路由模块 -->
       <router-view ></router-view>
        <!-- <div class="new_register text-center">
            <div class="container">
                <h3>新用户注册，即刻畅享顶级云通信产品和全方位服务 </h3>
                <a href="#">立即注册</a>
            </div>
        </div> -->
    </div>
    <div class="footer">
          <!-- <div class="zixun" @click="kefuQQ">
               <img src="@/assets/images/jy_A.png" alt="">
               <p>君语电话下载</p>
          </div> -->
         <Back-top :height="100" :bottom="100" >
           <div class="fanhuidingduan" @click="kefuQQ">
             在线咨询
           </div>
         </Back-top>
         
        <div class="contact">
            <!-- <div class="container">
                <Row>
                  <i-col span="12">
                        <div class="foot_block">
                            <div class="col col-md-4 text-left">
                                <div class="ft_title">关于我们</div>
                                <p>
                                  <a href="#">公司简介</a>
                                </p>
                                <p>
                                  <a href="#">联系我们</a>
                                </p>
                                <p>
                                  <a href="#">服务条款</a>
                                </p>
                                <p>
                                  <a href="#">发展历程</a>
                                </p>
                            </div>
                            <div class="col col-md-4 text-left">
                                <div class="ft_title">产品</div>
                                <p>
                                  <a href="#">云呼叫中心</a>
                                </p>
                                <p>
                                  <a href="#">君语小号</a>
                                </p>
                                <p>
                                  <a href="#">云通信能力</a>
                                </p>
                                <p>
                                  <a href="#">短信管理</a>
                                </p>
                            </div>
                            <div class="col col-md-4 text-left">
                                <div class="ft_title">开发者服务</div>
                                <p>
                                  <a href="#">开发文档</a>
                                </p>
                                <p>
                                  <a href="#">使用指南</a>
                                </p>
                                <p>
                                  <a href="#">博客</a>
                                </p>
                            </div>
                        </div>
                  </i-col>
                  <i-col span="6">
                      <div class="foot_block">
                            <div class="ft_title">联系我们</div>
                            <p class="phone">电话:0755-33165105</p>
                            <p>(服务时间: 周一至周六 9:00-18:00)</p>
                      </div>
                  </i-col>
                  <i-col span="6">
                    <div class="code text-center foot_block" > 
                        <div class="ft_title">关注君语</div>
                        <img src="" alt="">
                        <span>关注我，了解更多行业解决方案</span>
                    </div>
                  </i-col>
              </Row>
            </div> -->
            <div class="container friendly_link">
                <p class="lianjie">
                    <a href="#">友情链接:</a>
                    <span> | </span>
                     <a href="#">深海捷科技</a>
                    <span> | </span>
                     <a href="#">物联网</a>
                    <span> | </span>
                     <a href="#">CTI论坛</a>
                    <span> | </span>
                     <a href="#">问营企业服务平台</a>
                </p>
            </div>
            <div class="file_info container">
                <div class="file_value">
                    <p>
                      版权所有：深圳市君语科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank" class="icp">粤ICP备16065331号</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <p>Copyright © 2016-2020 junyu Technology  All Reserved.</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { EventBus } from "@/eventBus.js";
export default {
  name: 'home',
  data() {
    return {
      navBarFixed:false,  
      navData:[
                {
                    name: '首页',
                    path: 'NoData',
                    id: '1',
                },
                 {
                    name: '产品',
                    path: 'ShowMore',
                    id: '2',
                    
                },
                 {
                    name: '解决方案',
                    path: 'solution',
                    id: '3',
                    
                },
                {
                    name: '案例',
                    path: 'clients',
                    id: '4'
                },
                // {

                //   name:'开发文档',
                //   path:'open.https://open.voxcoo.com/doc.html',
                //   id:'4'
                // },
                // {
                //     name: '关于我们',
                //     path: 'support',
                //     id: '5'
                // },
              
      ],
      chanpinlist:[
        {
          id:1,
          name:'SaaS',
          text:[
                  {
                    id:1,
                    text:'闪兔云',
                    name:'hot',
                    path:'ShowMore'
                  },
                  {
                    id:2,
                    text:'拓客帮',
                  },
              ],
         },
         {
          id:2,
          name:'PaaS',
          // path:'ShowMore',
          text:[
                  {
                    id:1,
                    text:'云坐席',
                  },
                  {
                    id:2,
                    text:'云总机',
                  },
                  {
                    id:3,
                    text:'小君智能机器人'
                  },
                  {
                    id:4,
                    text:'云会议'
                  },
                  {
                    id:5,
                    text:'点击拨号'
                  },{
                    id:6,
                    text:'语音通知'
                  },
                  {
                    id:7,
                    text:'隐私电话'
                  },
                  {
                    id:8,
                    text:'语音验证码'
                  },

          ],
          
         },
         {
          id:3,
          name:'云通信',
          // path:'ShowMore',
          text:[
              {
                id:1,
                text:'君语网络电话',
              },
              {
                id:2,
                text:'君语SDK',
              },
              {
                id:3,
                text:'400电话'
              }
          ]
        },
         {
          id:4,
          name:'消息',
          // path:'ShowMore',
          text:[
            {
              id:1,
              text:'验证码短信'
            },
            {
              id:2,
              text:'会员营销短信'
            },
            {
              id:3,
              text:'通知短信'
            },
            {
              id:4,
              text:'视频短信'
            }
          ]
        },
         
        
      ],
      fanganlist:[
        {
          id:1,
          name:'电商解决方案',
          text:'保险解决方案',
          // path:'solution'
        },
         {
          id:2,
          name:'教育解决方案',
          text:'教育解决方案',
          // path:'solution'
          
        },
         {
          id:3,
          name:'金融解决方案',
          text:'金融解决方案',
          // path:'solution'
        },
        {
          id:4,
          name:'保险解决方案',
          text:'保险解决方案',
          // path:'solution'
        },
         
        
      ]
    }
  },
  methods:{
    hidemenu() {
        let scrollTop =
            window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop;
        if (scrollTop > 72) {
            this.navBarFixed = true
            $('#header').css('background','#00142e')
        }else{
            this.navBarFixed = false
            $('#header').css('background','')
        }
    },
    chanpin(){
      $('#wu').slideDown()
    },
    chanpin_leave(){
      $('#wu').hide()
    },
    SEL_CHANPIN(id){
       $('#wu').hide()
        id = 1
        setTimeout(()=>{
           EventBus.$emit('add',id)
        },100)
       
      //  this.$router.push({
      //    name:'ShowMore'
      //  })

    },
    fangan(){
      $('#jiejue').slideDown()
    },
    fangan_leave(){
      $('#jiejue').hide()
    },
    SEL_FANGAN(id){
       $('#jiejue').hide()
       setTimeout(()=>{
           EventBus.$emit('page',id)
        },100)
      //  this.$router.push({
      //    name:'solution'
      //  })
    },
    kefuQQ(){
            window.open("http://wpa.qq.com/msgrd?v=3&uin=234513612&site=www.cactussoft.cn&menu=yes")
    },
  },
 mounted(){
        window.addEventListener("scroll", this.hidemenu);
  },
    
    
}
</script>
<style lang="scss">
  @import "./home.scss";
  
</style>